<template>
<div>
  <v-menu v-if="!$vuetify.breakpoint.mobile"
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
          :position-x="menu.positionX"
          :position-y="menu.positionY"
          v-model="menu.show">
    <v-list class="pa-0" dense>
      <div v-for="op in visibleOptions" :key="op.action">
        <v-list-item @click="$emit(`click:item`, op.action)" :disabled="bind != null && op.disabledWhen != null && op.disabledWhen(bind)">
          <v-list-item-title :class="`${op.color}--text`">
            {{op.text}}
          </v-list-item-title>
          <v-list-item-action>
            <v-icon :class="`${op.color}--text`">{{op.icon}}</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <slot/>
      </div>
    </v-list>
  </v-menu>
  <v-dialog v-else v-model="menu.show" content-class="action-sheet">
    <v-card>
      <v-card-title class="pa-4">
        <span class="menu-title">
          {{title}}
        </span>
        <v-spacer/>
        <v-btn small icon @click="menu.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-list class="pa-0">
        <div v-for="op in visibleOptions" :key="op.action">
          <v-list-item @click="selectAction(op.action)" :disabled="bind != null && op.disabledWhen != null && op.disabledWhen(bind)">
            <v-list-item-title :class="`${op.color}--text`">
              {{op.text}}
            </v-list-item-title>
            <v-list-item-action>
              <v-icon :class="`${op.color}--text`">{{op.icon}}</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider/>
        </div>
        <slot/>
      </v-list>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  name: "ResponsiveMenu",
  props: ['title','opcoes','bind'],
  data () {
    return {
      menu: {
        show: false,
        positionX: null,
        positionY: null
      }
    }
  },
  computed: {
    visibleOptions() {
      return this.opcoes.filter(op => {
        if(op.hasOwnProperty('hiddenWhen') == null) return true;
        if(typeof op.hiddenWhen !== 'function') return true;
        return op.hiddenWhen(this.bind) !== true;
      })
    }
  },
  methods: {
    open(event) {
      setTimeout(() => {
        this.menu.show = false;
        this.menu.positionX = event.clientX;
        this.menu.positionY = event.clientY;
        this.$nextTick(() => {
          this.menu.show = true;
        })
      }, 100);

    },
    selectAction(action) {
      this.$emit(`click:item`, action);
      this.$nextTick(() => {
        this.menu.show = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.menu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 35px);
}
</style>