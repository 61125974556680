<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="500px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">

      <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
        <etapa-form v-model="item"/>
      </v-form>

    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn  color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import EtapaForm from "../forms/EtapaForm";
import {create, update} from "../../../api/etapas";
import {ApiError} from "../../../api/core";
export default {
  name: "CreateEditEtapaDialog",
  components: {EtapaForm},
  props: ['value','item'],
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return this.item?.id ? 'Editar Etapa' : 'Adicionar Etapa'
    }
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          if(this.item.id) {
            await update(this.item.id, this.item);
          } else {
            await create(this.item)
          }
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Etapa ${this.item.id ? 'atualizada' : 'criada'} com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível ${this.item.id ? 'atualizar' : 'criar'} a Etapa.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>

</style>